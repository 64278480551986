<template>
  <v-container class="mt-4">
    <page-main
      title="What makes a good resume?"
      nextText="Prep materials"
      buttonTabIndex="6"
      eventName="nav"
      @nav="nav('/PreparationMaterials')"
    >
      <ul class="text-body-big">
        <li class="mb-4">
          <span class="font-weight-bold">Highlight your impactful achievements.</span>
          <ul>
            <li>
              A common opportunity to improve a resume is to clearly articulate prior professional impact. Many resumes fall short of expectations by only focusing resume content on describing roles and responsibilities, but Resumation uses several resume-building methods to help you provide detail about your strongest professional experiences and how through those experiences you delivered results.
            </li>
          </ul>
        </li>
        <li class="mb-4">
          <span class="font-weight-bold">Tailor content to the job.</span>
          <ul>
            <li>
              Incorporate a balance of technical skills and interpersonal skills relevant to the job you're applying for. Align your resume's content with keywords from the job description to make it more appealing to potential employers.
            </li>
          </ul>
        </li>
        <li class="mb-4">
          <span class="font-weight-bold">Opt for a clean and easy-to-read format.</span>
          <ul>
            <li>
              Ensure your resume is formatted in a clear, consistent manner, free of grammatical errors for easy readability. Resumation offers ATS-friendly templates that are designed to be straightforward for both recruiters and resume scanning software. If needed, you can customize the format by downloading the editable document from Resumation.
            </li>
          </ul>
        </li>
        <li>
          <span class="font-weight-bold">Keep it concise.</span>
          <ul>
            <li>
              For students and those in the early stages of their careers, aim for a one-page resume. Professionals with over 10 years of experience may extend to two pages. Resumation Plus users can take advantage of the ‘Customize’ feature to adjust the content visibility, helping manage the resume length as appropriate.              
            </li>
          </ul>
        </li>
      </ul>
    </page-main>
  </v-container>
</template>

<script>
export default {
  name: "WhatMakesAGoodResume",
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  }
};
</script>

<style scoped>
  .container ul ul {
    list-style-type: disc;
  }
</style>
